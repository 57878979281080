// console.log('app js');
//
// 'use strict';
//
// var sentence = document.querySelector('.box');
// // var h1 = document.querySelector('h1');
// // var h2 = document.querySelector('h2');
//
// document.addEventListener('mousemove', function (e) {
//   var xpos = e.layerX || e.offsetX;
//   var ypos = e.layerY || e.offsetY;
//
//   var ax = -(window.innerWidth / 2 - xpos) / 100;
//   var ay = (window.innerHeight / 2 - ypos) / 40;
//
//   sentence.style.transform = 'rotateY(' + ax + 'deg) rotateX(' + ay + 'deg)';
// });

