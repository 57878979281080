$(document).ready(function () {

/*******************************************************************************
MDB
*******************************************************************************/
  $('[data-toggle="tooltip"]').tooltip()
  $('.mdb-select-contact, .select-option, .tabs-mobile select').material_select();
  $("#contacto-form").removeClass("waves-effect waves-light");


/*******************************************************************************
CAMBIO DE IMG EN HERO
*******************************************************************************/
  $(function () {
    if (matchMedia) {
      var mq = window.matchMedia("(min-width: 993px)");
      mq.addListener(WidthChange);
      WidthChange(mq);
    }

    function WidthChange(mq) {
      if (mq.matches) {
        $(".bg_images_dm").each(function () {
          var attr = $(this).attr('data-image-src');
          if (typeof attr !== typeof undefined && attr !== false) {
            $(this).css('background-image', 'url(' + attr + ')');
          }
        });
      } else {
        $(".bg_images_dm").each(function () {
          var attr = $(this).attr('data-image-src-sm');
          if (typeof attr !== typeof undefined && attr !== false) {
            $(this).css('background-image', 'url(' + attr + ')');
          }
        });
      }
    }
  });


/*******************************************************************************
MENU
*******************************************************************************/
  var header = $('.header'),
      navbar = $('.navbar'),
      masckMenu = $('.masck-menu'),

      timeline = new TimelineMax({ paused: true, reversed: true });

      CSSPlugin.defaultTransformPerspective = 1600;

      timeline.to(masckMenu, 0.5, {display:'block', autoAlpha: 1, ease: Power1.easeOut})
              .to(navbar, 0.5, {display:'block', left:"0px", ease: Power1.easeOut}, "-=0.65");


  $('.bt-menu').on('click', function (e) {
    e.preventDefault();
    $('body').toggleClass('menu-open');
    $('header').toggleClass('solid-header-menu');
    $(".menu-secondary, .submenu .open-slide").removeClass("active");
    timeline.reversed() ? timeline.play() : timeline.reverse();
  });

  $('.masck-menu').on('click', function (e) {
    e.preventDefault();
    $(".bt-menu").trigger( "click" );
  });

  $('.submenu .open-slide').on('click', function (e) {
    e.preventDefault();
    console.log("open-slide");
    var openSlide = $(this).attr("open-slide");
    $(".submenu a, .contact_form").removeClass("active");
    $(".menu-secondary").removeClass("large");
    $(this).addClass("active");
    $(".menu-secondary").addClass("active");
    $(".menu-secondary .submenu").css("display","none").removeClass("active");
    $(openSlide).css("display","block").addClass("active");
    TweenMax.to($('.menu-secondary .submenu li'), 0, {autoAlpha: 0, ease: Power1.easeOut });
    TweenMax.staggerTo($('.menu-secondary '+openSlide+' li'), .3, {delay:.6, autoAlpha: 1, ease: Power1.easeOut }, 0.1);
  });

  $('.contacto').on('click', function () {
    $(".menu-secondary").addClass("large");
    setTimeout(function() {
      $(".contact_form").addClass("active");
    }, 400);
  });


  var total_list_prod = 0;

  $(".sub-productos > li").each(function(index) {
    total_list_prod = total_list_prod+1;
  });
  var cant_list_prod = 100 / total_list_prod;
  $(".sub-productos > li").css('height', cant_list_prod + 'vh');

  var total_list_articulos = 0;
  $(".sub-articulos > li").each(function(index) {
    total_list_articulos = total_list_articulos+1;
  });
  var cant_list_articulos = 100 / total_list_articulos;
  $(".sub-articulos > li").css('height', cant_list_articulos + 'vh');



/*******************************************************************************
Swipper HERO
*******************************************************************************/
/*  var swiperH = new Swiper('.swiper-container-h', {
    pagination: '.swiper-pagination-h',
    direction: 'horizontal',
    parallax: true,
    paginationClickable: true,
    mousewheelControl: false,
    keyboardControl: false,
    resistanceRatio: 0,
    preventClicksPropagation: false,
    preventClicks: false,
    grabCursor:true,
    speed: 1200,
    spaceBetween: 0
  }); */
  
  
  $('.select-pais .select-option').on('change', function(e) {
    e.preventDefault();
    document.location.href = $(this).find(":selected").val();
  });
  
  $('.tabs.tabs-mobile').on('change', function(e) {
    e.preventDefault();
    document.location.href = $('.tabs ul li:nth-child(' + $(this).find(":selected").val() + ') a').attr('href');
  });


/*******************************************************************************
SVG TO HTML
*******************************************************************************/
  jQuery('img.svg').each(function () {
    var $img = jQuery(this);
    var imgID = $img.attr('id');
    var imgClass = $img.attr('class');
    var imgURL = $img.attr('src');

    jQuery.get(imgURL, function (data) {
      var $svg = jQuery(data).find('svg');
      if (typeof imgID !== 'undefined') {
        $svg = $svg.attr('id', imgID);
      }
      if (typeof imgClass !== 'undefined') {
        $svg = $svg.attr('class', imgClass + ' replaced-svg');
      }
      $svg = $svg.removeAttr('xmlns:a');
      if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
        $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
      }
      $img.replaceWith($svg);
    }, 'xml');
  });


/*******************************************************************************
 LIKE +1
 *******************************************************************************/
/*    $('.like').on('click', function (e) {
      e.preventDefault();
      $(this).removeClass("active");
      $(this).addClass("active");

      $(this).find("span").html(function(i, val) {
        return val * 1 + 1;
      });

    }); */


/*******************************************************************************
SEARCH
*******************************************************************************/
  $(".ico-search").on("click", function (e) {
    e.preventDefault();
    $("#search").toggleClass("open");
  });

  $("#search .closebtn").on("click", function (e) {
    $("#search").toggleClass("open");
  });



}); // End




// FORMULARIO SALESFORCE
$(document).ready(function() {
    $('.documento').on('change', function() {
        // Obtener el valor seleccionado
        var selectedValue = $(this).val();
        console.log('Tipo de documento seleccionado:', selectedValue);

        // Cambiar dinámicamente el atributo maxlength del input según la opción seleccionada
        switch (selectedValue) {
            case 'DNI':
                $('.numero').attr('maxlength', 8);
                $('.numero').attr('minlength', 8);
                $('.numero').removeClass('disabled');
                $('.numero').addClass('enabled');
                break;
            case 'Carnet de Extranjería':
                $('.numero').attr('maxlength', 12);
                $('.numero').attr('minlength', 12);
                $('.numero').removeClass('disabled');
                $('.numero').addClass('enabled');
                break;
            case 'RUC':
                $('.numero').attr('maxlength', 11);
                $('.numero').attr('minlength', 11);
                $('.numero').removeClass('disabled');
                $('.numero').addClass('enabled');
                break;
            default:
                // Si no hay selección, restaurar el valor predeterminado
                $('.numero').attr('maxlength', 20);
                $('.numero').attr('minlength', 20);
                $('.numero').val(''); // Vaciar el campo
                $('.numero').removeClass('enabled');
                $('.numero').addClass('disabled');
        }

        // Quitar la propiedad 'required' si el valor seleccionado es vacío
        if (selectedValue === '') {
            $('.numero').prop('required', false);
        }
        else {
            // Establecer la propiedad 'required' si el valor seleccionado no es vacío
            $('.numero').prop('required', true);
        }
    });

    // Obtener el elemento con el ID 'phone'
    try {
        var phoneInput = document.getElementById('phone');

        phoneInput.addEventListener('input', function(event) {
            // Obtener el valor actual del campo de teléfono
            var phoneNumber = event.target.value;

            // Eliminar caracteres no numéricos usando una expresión regular
            phoneNumber = phoneNumber.replace(/[^\d]/g, '');

            // Actualizar el valor del campo de teléfono
            event.target.value = phoneNumber;
        });
    }
    catch (error) {
       // console.log("Ocurrió un error:", error.message);
    }
});