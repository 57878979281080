$(document).ready(function () {
  // EVENTO CUSTOM HERO BANNER ------------------------------------>
  $(".swiper-container section.swiper-slide a").click(function () {
    DY.API("event", {
      name: "Click Hero banner",
    });
  });

  // EVENTO CUSTOM IR A COMPRAR ------------------------------------>
  $("header .links a:first-child").click(function () {
    DY.API("event", {
      name: "Click Ir a comprar",
      properties: {
        section: "Header",
        title: "",
      },
    });
  });

  $("nav.navbar .menu.menu-primary li:nth-child(5n) a.menu-btn").click(function () {
      DY.API("event", {
        name: "Click Ir a comprar",
        properties: {
          section: "Navbar",
          title: "",
        },
      });
    }
  );

  // EVENTO CUSTOM CLICK NAVBAR ------------------------------------>
  $("nav.navbar .menu.menu-primary ul.submenu li a").click(function () {
    var navLinkItem = $(this).clone().children().remove().end().text();
    DY.API("event", {
      name: "Click Navbar",
      properties: {
        title: navLinkItem,
      },
    });
  });

  // EVENTO CUSTOM CLICK PRODUCTO ---------------------------------->
  $(".productos section.grid .element-item.cards").click(function (event) {
    var productoTitulo = $(this).find(".title").text();

    if (event.target.className === "link" && event.target.tagName == "A") {
      DY.API("event", {
        name: "User Click Product",
        properties: {
          title: productoTitulo,
        },
      });
    }

    if (
      event.target.className === "btn-comprar" &&
      event.target.tagName == "A"
    ) {
      DY.API("event", {
        name: "Click Ir a comprar",
        properties: {
          section: "Producto",
          title: productoTitulo,
        },
      });
    }
  });

  $("section .relacionados.productos_relacionados li>a").click(function () {
    var productoTitulo = $(this).find(".text").text();

    DY.API("event", {
      name: "User Click Product",
      properties: {
        title: productoTitulo,
      },
    });
  });

  $(".productos.producto_detalle section .swiper-container a.btn-comprar").click(function () {
    var productoTitulo = $("section .swiper-container .title h1").text();

    DY.API("event", {
      name: "Click Ir a comprar",
      properties: {
        section: "Producto",
        title: productoTitulo,
      },
    });
  });

  $("section .relacionados li>a[data-id]").click(function () {
    var postTitulo = $(this).find("span.text").text();

    DY.API("event", {
      name: "Click Post",
      properties: {
        title: postTitulo,
      },
    });
  });

  $("nav.navbar .menu.menu-secondary ul.submenu.sub-productos li a").click(function () {
      var filtroTitulo = $(this).find("span").text();

      DY.API("event", {
        name: "Filter Items",
        properties: {
          dyType: "filter-items-v1",
          filterType: "Tipo de producto",
          filterStringValue: filtroTitulo,
        },
      });
    }
  );

  $(".productos nav.tabs li a").click(function () {
    var filtroTitulo = $(this).find("span").text();

    DY.API("event", {
      name: "Filter Items",
      properties: {
        dyType: "filter-items-v1",
        filterType: "Tipo de productos",
        filterStringValue: filtroTitulo,
      },
    });
  });

  $("nav.navbar .menu.menu-secondary ul.submenu.sub-articulos li a").click(function () {
      var filtroTitulo = $(this).find("span").text();

      DY.API("event", {
        name: "Filter Items",
        properties: {
          dyType: "filter-items-v1",
          filterType: "Tipo de notas",
          filterStringValue: filtroTitulo,
        },
      });
    }
  );

  $(".articulos section#filters.orden a[data-filter='.ordenar-likes']").click(function () {
      DY.API("event", {
        name: "Sort Items",
        properties: {
          dyType: "sort-items-v1",
          sortBy: "Likes",
          sortOrder: "DESC",
        },
      });
    }
  );

  $(".articulos section#filters.orden a[data-sort-value='number']").click(function () {
      DY.API("event", {
        name: "Sort Items",
        properties: {
          dyType: "sort-items-v1",
          sortBy: "Fecha",
          sortOrder: "DESC",
        },
      });
    }
  );

  $(".productos.producto_detalle section .swiper-container .swiper-pagination .swiper-pagination-bullet").click(function () {
    var attribute = $(this).find("span").text();

    DY.API("event", {
      name: "Change Attribute",
      properties: {
        dyType: "change-attr-v1",
        attributeType: "Variedades",
        attributeValue: attribute.trim(),
      },
    });
  });
});
